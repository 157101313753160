:root {
  --primary-clr: #FADD47;
  --secondary-clr: #464255;
  --txt-clr: #A3A3A3;
  --bg-clr: rgba(203, 180, 244, 0.10);
  --primary-font: "Poppins";
  --heading-clr: #5D586C;
  --blue-clr: #00CFE8;
  --orange-clr: #FF9F43;
  --red-clr: #EA5455;
  --spacer: 28px;
  --spacer-24: 24px;
  --spacer-20: 20px;
}

* {
  font-family: var(--primary-font);
}

::selection {
  background-color: #000;
  color: #fff;
}

a {
  text-decoration: none;
}

.spacer {
  padding: var(--spacer) 0px;
}

.p-relative {
  position: relative;
}


input:focus,
select:focus,
textarea:focus {
  box-shadow: none !important;
  border-color: #eee !important;
}

img {
  max-width: 100%;
}


/* Login Page CSS Start */

.login-img-blk {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
}

.login-img-blk img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-img-blk::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.71);
  z-index: 1;
}

.login-img-blk::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 255px;
  height: 335px;
  background: url("/public/assets/images/logo/nexmil-logo-yl.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.login-main-blk {
  width: 100%;
  height: 100vh;
  position: relative;
}

.login-logo {
  padding: 30px 0px 0px 40px;
}

.login-form-blk {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 35%);
}

.login-form-blk h1 {
  font-size: 32px;
  font-weight: 700;
  color: #000;
  font-family: var(--primary-font);
  text-align: center;
}

.login-form-blk p {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0px;
}

.login-form-blk form {
  margin-top: 40px;
}

.login-form-blk form .form-group:not(:nth-child(2)) {
  margin-bottom: 12px;
}

.login-form-blk form .form-group input {
  padding: 18px 48px;
  background: rgba(255, 255, 255, 0.91);
  border: 1px solid #000;
  border-radius: 50px;
  font-size: 16px;
}

.login-form-blk form .form-group input::placeholder {
  color: #000;
}

span.input-icon {
  position: absolute;
  content: "";
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  width: 16px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

span#eye {
  position: absolute;
  content: "";
  top: 50%;
  right: 20px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

span#eye i {
  font-size: 14px;
  color: #000;
}

.flex-blk {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.forgot-password-blk {
  margin-top: 10px;
}

a.forgot-password {
  font-size: 16px;
  font-weight: 400;
  color: #2f2f2f;
  text-transform: capitalize;
}

.login-btn-blk {
  text-align: center;
  margin-top: 45px;
}

.login-btn-blk .btn-style {
  width: 100%;
  font-size: 16px !important;
  padding: 16px 10px;
  border-radius: 50px;
  background: var(--primary-clr);
  color: #000;
  font-family: var(--secondary-font);
  font-weight: 600;
  border: 0px;
  transition: all .3s ease-in-out;
}

.btn-style:hover {
  background: #000;
  color: #fff;
}



/* Login Page CSS End */






/*--  MEDIA QURIES CSS START --*/

@media screen and (min-width: 1800px) {
  .login-container {
    max-width: calc(100% - 120px);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1800px) {}

@media screen and (max-width: 1300px) {}


@media screen and (max-width: 1200px) {
  .login-container {
    max-width: 100%;
  }

  .main-wrapper {
    margin-left: 0px;
  }

  .login-form-blk {
    width: calc(100% - 30%);
  }

  .login-img-blk img {
    object-position: left;
  }

}

@media screen and (max-width: 991px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 576px) {

  .login-logo {
    padding: 25px 0px 0px 25px;
  }

  .login-form-blk {
    width: calc(100% - 15%);
  }

  .login-form-blk h1 {
    font-size: 26px;
  }

  .login-form-blk p {
    font-size: 15px;
  }

  .login-form-blk form {
    margin-top: 50px;
  }

  .form-block input,
  .form-block input::placeholder {
    font-size: 14px;
  }



}