.error {
  font-weight: 400 !important;
  font-size: 13px !important;
  color: red !important;
  margin: 5px 15px;
}

.loadingDiv {
  display: flex !important;
  justify-content: center !important;
  height: 70vh !important;
  align-items: center !important;
}

/* nexmil loader css */

.loading-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.loading-container {
  width: 100%;
  max-width: 520px;
  text-align: center;
  position: relative;
  margin: 0 32px;
}

.loading-container:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #fadd47;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  -webkit-animation: movingLine 2.4s infinite ease-in-out;
  animation: movingLine 2.4s infinite ease-in-out;
}

@-webkit-keyframes movingLine {
  0% {
    opacity: 0;
    width: 0;
  }

  33.3%,
  66% {
    opacity: 0.8;
    width: 100%;
  }

  85% {
    width: 0;
    left: initial;
    right: 0;
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 0;
  }
}

@keyframes movingLine {
  0% {
    opacity: 0;
    width: 0;
  }

  33.3%,
  66% {
    opacity: 0.8;
    width: 100%;
  }

  85% {
    width: 0;
    left: initial;
    right: 0;
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 0;
  }
}

.loading-text {
  font-size: 5vw;
  font-family: 'Poppins';
  color: #000;
  line-height: 64px;
  letter-spacing: 10px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-evenly;
}

.loading-text span {
  -webkit-animation: moveLetters 2.4s infinite ease-in-out;
  animation: moveLetters 2.4s infinite ease-in-out;
  transform: translatex(0);
  position: relative;
  display: inline-block;
  opacity: 0;
  text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);
}

.loading-text span img.img-letters {
  /* height: 75px; */
  height: 40px;
}

.loading-text span:nth-child(1) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.loading-text span:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loading-text span:nth-child(3) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.loading-text span:nth-child(4) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.loading-text span:nth-child(5) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.loading-text span:nth-child(6) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes moveLetters {
  0% {
    transform: translateX(-15vw);
    opacity: 0;
  }

  33.3%,
  66% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(15vw);
    opacity: 0;
  }
}

@keyframes moveLetters {
  0% {
    transform: translateX(-15vw);
    opacity: 0;
  }

  33.3%,
  66% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(15vw);
    opacity: 0;
  }
}

@media screen and (max-width:576px) {
  .loading-text span img.img-letters {
    height: 30px;
  }
}

/* nexmil loader css */

/*toggle*/

span.toggle-box {
  display: inline-flex;
}

.toggle-box input {
  display: none;
}

.toggle-box input+label {
  width: 37px;
  height: 22px;
  background: var(--orange-clr);
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  transition: all .5s ease;
}

.toggle-box input+label::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 3px;
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 50%;
  transition: all .5s ease;
}

.toggle-box input:checked+label {
  background: var(--blue-clr);
  box-shadow: 0px 2px 4px rgba(165, 163, 174, 0.3);
}

.toggle-box input:checked+label::before {
  left: calc(100% - 17px);
}

span.td-status {
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 600;
  white-space: nowrap;
}

.success-state {
  background: rgb(231 231 231 / 40%);
  color: var(--secondary-clr);
}

.active-state {
  background: rgba(0, 207, 232, 0.16);
  color: var(--blue-clr);
}

.inactive-state,
.pending-state {
  background: rgba(255, 159, 67, 0.16);
  color: var(--orange-clr);
}